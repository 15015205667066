#skipNavigation a {
  padding:10px;
  font-size: 24px;
  font-weight: bold;
	position: absolute;
	top:-100px;
	left:0px;
	color:white;
	border-right:1px solid white;
	border-bottom:1px solid white;
	border-bottom-right-radius:8px;
	background:#BF1722;
	-webkit-transition: top 1s ease-out;
    transition: top 1s ease-out;
    z-index: 10000;
}

#skipNavigation a:focus {
	position:absolute;
	left:0px;
	top: 0px;
	outline-color:transparent;	
	-webkit-transition: top .1s ease-in;
    transition: top .1s ease-in;
}
