
.AriaMenuButton {
  display: flex;
  align-items: center;
}

.AriaMenuButton-trigger {
  cursor: pointer;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent; /* black */
  /* background-image: linear-gradient(black, black); */
  color: white;
  border: 0px solid black;
}

.AriaMenuButton-trigger::after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: 3px;
}


.AriaMenuButton-trigger:hover,
.AriaMenuButton-trigger:focus,
.AriaMenuButton-trigger.is-open {
  background-color: transparent; /* black; */
  /*background-image: linear-gradient(black, black);*/
  color: white;
  border: 0px solid black;
}

.AriaMenuButton-menu {
  background: black;
  border: 1px solid rgba(200, 200, 200, 0.4);
  list-style-type: none;
  position: absolute;
  top: 100%;
  right: 0px;
  z-index: 99;
  padding-left: 0;
  border-radius: 3px;
  margin: 24px 0 0 0;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  /* width: 215px; */
  width: 200px;
}

.AriaMenuButton-menu::before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) black rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  position: absolute;
  top: -12px;
  left: 170px;
  z-index: 89;
}

.AriaMenuButton-menu::after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) black rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  position: absolute;
  top: -14px;
  left: 170px;
  z-index: 88;
}

.AriaMenuButton-menu--flushRight {
  right: 0;
}

.AriaMenuButton-menuItem {
  cursor: pointer;
  padding: 12px;
  border-bottom: 1px solid #EEE;
  text-align: left;
}

.AriaMenuButton-menuItemWrapper:first-of-type .AriaMenuButton-menuItem {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}

.AriaMenuButton-menuItemWrapper:last-of-type .AriaMenuButton-menuItem {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.AriaMenuButton-menuItem:hover,
.AriaMenuButton-menuItem:focus {
  background-color: #333;
  color: #fff;
}

.AriaMenuButton-menuItem.is-selected {
  cursor: default;
  font-size: 14pt;
  font-weight: bold;
}





.roles-AriaMenuButton-menu {
  background: #ffff;
  color: #3b3b3b;
  border: 1px solid rgba(200, 200, 200, 0.4);
  list-style-type: none;
  position: absolute;
  top: 100%;
  right:30px;
  z-index: 99;
  padding-left: 0;
  border-radius: 3px;
  margin: 24px 0 0 0;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
  /* width: 215px; */
}

.role-select::before {
  border: inset 6px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)  #ffff rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  position: absolute;
  top: -12px;
  left: 112px;
  z-index: 89;
}

.role-select::after {
  border: inset 7px;
  content: "";
  display: block;
  height: 0;
  width: 0;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0)  #ffff rgba(0, 0, 0, 0);
  border-bottom-style: solid;
  position: absolute;
  top: -14px;
  left: 112px;
  z-index: 88;
}
